<template>
	<div class="advances">
		<div class="index-top">
			<div class="index-title">
				<div class="title">充值工具</div>
				<div class="anniu"><a href="#list"><el-button type="primary">历史订单</el-button></a></div>
				<div class="anniu"><el-button type="primary" @click="toExpeAdvances">体验自助充值</el-button></div>
			</div>
			<div class="index-prompt">
				<!-- <div class="text">您当前垫款剩余额度为<span style="color:#f0?0">￥{{CompanyList.payment_price - CompanyList.use_price}}</span></div> -->
			</div>
		</div>
		<div class="advances-main">
			<div class="gongju">
				<div class="gongdan">
					<div class="dan">
						<div class="text"><span>*</span>充值类型：</div>
						<div class="inputbox">
							<el-radio v-model="radio" label="1">付款充值</el-radio>
							<el-radio v-model="radio" label="2">垫款充值</el-radio>
						</div>
					</div>
				</div>
				<AdvPayment ref="AdvPayment" v-if="radio == '1'"></AdvPayment>
				<AdvMoney ref="AdvMoney" v-if="radio == '2'"></AdvMoney>
			</div>
			<Conterlist ref="Conterlist" id="list"></Conterlist>
		</div>
		
		<AdvancesList ref="AdvancesList" id="list"></AdvancesList>
	</div>
</template>

<script>
import AdvMoney from './AdvMoney.vue'
import AdvPayment from './AdvPayment.vue'
import AdvancesList from './advancesList.vue'
import Conterlist from '../counter/counterList.vue'
export default {
	data () {
		return {
			radio:"1",
			token:JSON.parse(localStorage.getItem('TZ-USER')).token,
			is_operating_type:'',
			CompanyList: [],//企业列表
			company_id: '',//企业ID
			AgentAccount: [],//企业下对应的账户列表
			agent_account_id: '',//充值账户id
			fileListUpload:[],//上传的文件列表
			file_id:'',//上传的文件id
			order_price:'',//图片文件金额
			origin:window.location.origin,//url链接
			delenteAnniu:false,//禁用按钮
		}
	},
	components:{
		AdvancesList,
		AdvPayment,
		AdvMoney,
		Conterlist,
	},

	mounted(){
		this.ongetCompanyList()
	},

	methods: {
		//跳转体验页面
		toExpeAdvances(){
			this.$router.push('/experience/advances')
		},
		//获取企业列表
		ongetCompanyList(){
			this.$service.get(this.$api.getCompanyList,{}, (res)=> {
				if(res.code == '200'){
					this.CompanyList = res.data
				}
			})
		},
		//企业id选择后出发事件
		onEnterprise(){
			this.ongetAgentAccount()
		},
		//获取充值账户列表
		ongetAgentAccount(){
			var prame = {
				company_id:this.company_id
			}
			this.$service.get(this.$api.getAgentAccount,prame, (res)=> {
				if(res.code == '200'){
					this.AgentAccount = res.data
				}
			})
		},
		//识别文件金额
		ongetImageOcr(){
			var prame = {
				file_id:this.file_id
			}
			this.$service.post(this.$api.getImageOcr,prame, (res)=> {
				if(res.code == '200'){
					this.order_price = res.data.price
				}
			})
		},
		//充值账户id选择前触发事件
		onAgentVisible($event){
			if($event && this.company_id == ''){
				this.$message({message: '请先选择企业id',type: 'warning'});
			}
		},
		//上传文件移出
		onRemoveupload(file, fileList){
			console.log('上传文件移出',fileList)
			this.fileListUpload = fileList
			this.file_id = ''
			this.$message({message: '文件移出成功，请重新上传文件',type: 'success'});
		},
		//文件上传成功
		onSuccessupload(res, file,fileList){
			console.log('文件上传成功',res)
			if (fileList.length > 1) {
				fileList.splice(0, 1);
			}	
			if(res.code == 200){
				this.file_id = res.data.id
				this.ongetImageOcr()
				console.log(this.file_id)
			}
		},
		//文件上传成功，失败 所有都会调用
		onChangeUpload(file,fileList){
			// if (fileList.length > 1) {
			// 	fileList.splice(1, 1);
			// }		
			console.log(file,'文件上传成功，失败 所有都会调用')
		},
		//确认提交
		onSubmit(){
			if(this.is_operating_type == ''){this.$message({message: '请选择充值类型',type: 'warning'}); return}
			if(this.company_id == ''){this.$message({message: '请先选择企业id',type: 'warning'}); return}
			if(this.agent_account_id == ''){this.$message({message: '请先选择充值账户',type: 'warning'}); return}
			if(this.file_id == ''){this.$message({message: '请先上传图片',type: 'warning'}); return}
			if(this.order_price == ''){this.$message({message: '正在识别图片金额',type: 'warning'}); return}
			var prame = {
				is_operating_type:this.is_operating_type,
				company_id:this.company_id,
				agent_account_id:this.agent_account_id,
				file_id:this.file_id,
				order_price:this.order_price
			}
			this.delenteAnniu = true
			this.$service.post(this.$api.placeTheOrder,prame, (res)=> {
				if(res.code == '200'){
					this.$message({message: '提交成功',type: 'success'});
					this.onChongxin()
					this.advancesList()
				}
			})
			setTimeout(() => {
				this.delenteAnniu = false
			}, 1000);
		},
		//重新填写
		onChongxin(){
			this.company_id = '',
			this.agent_account_id = ''
			this.file_id = ''
			this.order_price = ''
			this.fileListUpload = []
		},
		//跳转列表
		advancesList(){
			this.$router.go(0);
			// this.$router.push('/advances/advancesList')
		},
	}
}
</script>

<style lang="scss" scoped>
.advances{
	.advances-main{
		display: flex;
		margin: 20px 0;
		.gongju{
			width: 50%;
		}
		.conterlist{
			margin-left: 20px;
			width: 50%;
		}
		.gongdan{
			display: block;
			.enderQueren{
				text-align: center;
				width: 800px;
			}
			.dan{
				.amount{
					color: #f00;
					font-size: 24px;
					font-weight: 500;
				}
			}
		}
	}
}
@media screen and (max-width: 750px) {
	.advances{
		.advances-main{
			display: block;
			.gongdan .dan:last-child .text{
				margin-top: 0;
			}
			.gongju{
				width: auto;
			}
			.conterlist{
				width: auto;
				margin-left: 0;
			}
		}
	}
	.advances .advances-main .gongdan .enderQueren{
		width: auto;
	}
}
</style>
<style lang="scss">
.advances{
	.advances-main{
		.conterlist{
			.cell{
				text-align: center;
			}
			.jieguo{
				cursor: pointer;
			}
		}
	}
}
</style>
