<template>
	<div class="gongdan">
		<!-- <div class="dan">
			<div class="text"><span>*</span>充值类型：</div>
			<div class="inputbox">
				<el-radio v-model="is_operating_type" label="1">代运营</el-radio>
				<el-radio v-model="is_operating_type" label="2">自运营</el-radio>
			</div>
		</div> -->
		<!-- <div class="dan">
			<div class="text"><span>*</span>群名：</div>
			<div class="inputbox">
				<el-select v-model="company_id" value-key="id" @change="onEnterprise" placeholder="请选择群名">
					<el-option
						v-for="item in CompanyList.list"
						:key="item.id"
						:label="item.company_name"
						:value="item">
					</el-option>
				</el-select>
			</div>
		</div> -->
		<div class="dan">
			<div class="text"><span>*</span>当前垫款额度：</div>
			<div class="inputbox huakuai" style="display:flex;">
				<!-- <el-slider v-model="order_price" :max="order_price_max" :min="0" :step="100"  show-input :disabled='orderDisabled'></el-slider> -->
				<div class="amount">￥{{order_price}}</div>
				<div class="tishi" style="margin-left:auto;">{{trichText}}</div>
			</div>
		</div>
		<div class="zengjia" v-for="(itemJia,key) in zengjiaList" :key="key">
			<div class="dan czzhjia">
				<div class="text">充值账户（{{key+1}}）<span v-if="zengjiaList.length > 1" @click="onJiaDel(key)">删除</span></div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>充值账户：</div>
				<div class="inputbox">
					<el-select v-model="itemJia.agent_account_name" @visible-change="onAgentVisible" @change="onAgentChange" placeholder="请选择充值账户" >
						<el-option
						v-for="item in AgentAccount"
						:key="item.id"
						:label="item.agent_account_name + '（' + item.agent_account + '）'"
						:value="item.id">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>充值金额：</div>
				<div class="inputbox huakuai">
					<el-input v-model="itemJia.agentData" placeholder="请输入该账户充值金额" type="text" oninput="value=value.replace(/[^\d\.]/g,'')"></el-input>
				</div>
			</div>
		</div>
		<div class="dan anniujia">
			<div class="text"><span></span></div>
			<div class="inputbox huakuai">
				<el-button icon="el-icon-plus" @click="onIncrease" round>增加充值账户</el-button>
			</div>
		</div>
		<div class="dan" v-if="zengjiaList[0].agentData">
			<div class="text"><span>*</span>广告币到账时间：</div>
			<div class="inputbox">
				<el-select v-model="arriveTime" value-key="id" placeholder="请选择到账时间" >
					<el-option
					v-for="(item,key) in timer_list"
					:key="key"
					:label="item.show_time"
					:value="item">
					</el-option>
				</el-select>
			</div>
		</div>
		<div class="enderQueren">
			<el-button type="primary" @click="onSubmit" :disabled="delenteAnniu">确认提交</el-button>
			<el-button type="info" @click="onChongxin" :disabled="delenteAnniu">重新填写</el-button>
		</div>
	</div>
</template>

<script>
import AdvancesList from './advancesList.vue'
export default {
	data () {
		return {
			token:JSON.parse(localStorage.getItem('TZ-USER')).token,
			is_operating_type:'1',
			CompanyList: [],//企业列表
			company_id: '',//企业ID
			companyData:{},
			AgentAccount: [],//企业下对应的账户列表
			agent_account_id: '',//充值账户id
			fileListUpload:[],//上传的文件列表
			file_id:'0',//上传的文件id
			order_price:null,//垫款金额
			order_price_max:0,//垫款金额最大值
			origin:window.location.origin,//url链接
			delenteAnniu:false,//禁用按钮
			orderDisabled:true,
			agentList:[],//账户充值金额分
			agent_account_name:[],
			zengjiaList:[
				{
					agent_account_name:'',
					agentData:'',
				}
			],
			timer_list:[],//到账时间
			arriveTime:'',
			trichText:'',//随机一条提示
		}
	},
	components:{
		AdvancesList,
	},

	mounted(){
		// this.ongetCompanyList()  //获取群列表
		this.ongetAppointTimer()  //选择到账时间
		this.ongetDubVerbalTrick() //随机提醒
		this.ongetAgentAccount()  //获取账户列表
	},

	methods: {
		//随机一条提示
		ongetDubVerbalTrick(){
			var param = {
				type:2,
			}
			this.$service.get(this.$api.getDubVerbalTrick,param, (res)=> {
				if(res.code == '200'){
					this.trichText = res.data
				}
			})
		},
		//获取到账时间
		ongetAppointTimer(){
			this.$service.get(this.$api.getAppointTimer,{}, (res)=> {
				if(res.code == '200'){
					res.data.timer_list.forEach((v,k) => {
						v.id = k+1
					});
					this.timer_list = res.data.timer_list
					this.arriveTime = res.data.timer_list[0]
				}
			})
		},
		//增加充值账户
		onIncrease(){
			this.zengjiaList.push({
				agent_account_name:'',
				agentData:'',
			})
		},
		//删除增加的元素对象
		onJiaDel(key){
			this.zengjiaList.splice(key,1)
		},
		// 充值金额，充值账户列
		onTopUpAmout(){
			this.agentList = []
			this.agent_account_id = []
			this.zengjiaList.forEach((v,k) => {
				this.agentList.push(v.agentData)
				this.agent_account_id.push(v.agent_account_name)
			});
		},
		//获取企业列表**********
		ongetCompanyList(){
			this.$service.get(this.$api.getCompanyList,{}, (res)=> {
				if(res.code == '200'){
					this.CompanyList = res.data
					if(res.data.corporate_account){
						this.order_price_max = res.data.corporate_account.payment_price - res.data.corporate_account.use_price
						this.order_price = res.data.corporate_account.payment_price - res.data.corporate_account.use_price
					}
				}
			})
		},
		//企业id选择后出发事件
		onEnterprise(val){
			this.agentList = []//清空每个充值金额
			this.agent_account_id = []//清空充值账户id
			this.agent_account_name = []//清空充值账户文字
			this.zengjiaList = [
				{
					agent_account_name:'',
					agentData:'',
				}
			]
			// this.company_id = val.id
			
			if(this.order_price_max != 0){
				this.orderDisabled = false
			}
			this.ongetAgentAccount(val.id)
		},
		onAgentChange(e,r){
			// var accid = []
			// e.forEach((v,k) => {
			// 	accid.push(v.id)
			// });
			// this.agent_account_id = accid
			// this.agentList = []
		},
		
		//获取充值账户列表
		ongetAgentAccount(id){
			var prame = {
				// company_id:id
			}
			this.$service.get(this.$api.getAgentAccount,prame, (res)=> {
				if(res.code == '200'){
					this.AgentAccount = res.data
				}
			})
		},
		
		//充值账户id选择前触发事件
		onAgentVisible($event){
			// if($event && this.company_id == ''){
			// 	this.$message({message: '请先选择企业',type: 'warning'});
			// }
		},
		// 判断agentList不能为空
		onAgeList(){
			if(this.agentList.length != this.agent_account_id.length){return true}
			else {
				var fatr = ''
				this.agentList.find(value => {
					if (value == '' || value == undefined){
						fatr=true
					}
				})
				return fatr
			}
		},
		//确认提交
		onSubmit(){
			this.onTopUpAmout()
			if(this.is_operating_type == ''){this.$message({message: '请选择充值类型',type: 'warning'}); return}
			// if(this.company_id == ''){this.$message({message: '请先选择企业id',type: 'warning'}); return}
			if(this.onAgeList()){this.$message({message: '请填写每个账户充值的金额',type: 'warning'}); return}
			var prame = {
				is_operating_type:this.is_operating_type,
				// company_id:this.company_id.id,
				agent_account_id:this.agent_account_id,
				file_id:this.file_id,
				order_price:0,
				is_payment_wat:2,
				user_record_price:this.agentList,//每个金额
				start_time:this.arriveTime.start_time,//充值到账时间
				end_time:this.arriveTime.end_time,//充值到账时间
			}
			this.delenteAnniu = true
			this.$service.post(this.$api.placeTheOrder,prame, (res)=> {
				if(res.code == '200'){
					if(res.data == null){
						this.$message({message: '提交成功',type: 'success'});
						setTimeout(() => {
							this.onChongxin()
							this.advancesList()
						}, 1000);
					}else if(res.data.status == 0){
						this.ongetAppointTimer()
						this.$message({message: res.msg,type: 'warning'});
					}
				}
			})
			setTimeout(() => {
				this.delenteAnniu = false
			}, 1000);
		},
		//重新填写
		onChongxin(){
			this.company_id = '',
			this.agent_account_id = ''
			this.file_id = '0'
			// this.order_price = '0'
			this.fileListUpload = []
		},
		//跳转列表
		advancesList(){
			this.$router.go(0);
			// this.$router.push('/advances/advancesList')
		},
	}
}
</script>

<style lang="scss" scoped>
.advances{
	.advances-main{
		margin-top: 20px;
		.gongdan{
			display: block;
			.enderQueren{
				text-align: center;
				width: 800px;
			}
			.dan{
				
				.huakuai{
					// width: 100%;
					// position: relative;
					.el-slider{
						padding-left: 100px;
					}
					.tishi{
						margin-left: 20px;
						color: #999;
					}
				}
				.amount{
					// top: 2px;
					// position: absolute;
					color: #f00;
					font-size: 24px;
					font-weight: 500;
				}
				&.anniujia{
					margin-top: 20px;
				}
				&.czzhjia{
					padding-bottom: 10px;
					span{
						cursor: pointer;
					}
				}
			}
			.zengjia{
				// padding-top: 20px;
				// border-bottom: 1px solid #ededed;
				.dan{
					margin-bottom: 0;
					&:nth-child(3){
						.inputbox{
							padding: 20px 0;
							border-bottom: 1px solid #ededed;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 750px) {
	.advances .advances-main .gongdan .enderQueren{
		width: auto;
	}
	.gongdan .dan:last-child .text{
		margin-top: 0;
	}
}
</style>
