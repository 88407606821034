<template>
	<div class="advancesList">
		<div class="index-top" v-if="$route.path != '/index'">
			<div class="index-title">
				<div class="title">自助充值历史订单</div>
			</div>
			<div class="index-prompt">
			</div>
		</div>
		<div class="advancesList-main">
			<el-table ref="refTable" :data="OrderList" border style="width: 100%" @row-click="clickTable" @expand-change="onChangeTableEx" :expand-row-keys="expands" :row-key="getRowKeys">
				<el-table-column type="expand">
					<template slot-scope="props">
						<el-form label-position="left" inline class="demo-table-expand">
							<el-form-item :label="item.top_agent_account.agent_account_name + '(' + item.top_agent_account.agent_account + ')：'" v-for="(item,key) in orderDetails" :key="key">
								<span>￥{{item.actual_price}}</span>
							</el-form-item>
						</el-form>
					</template>
				</el-table-column>
				<el-table-column prop="order_num" label="订单编号"></el-table-column>
				<el-table-column prop="order_price" label="打款金额">
					<template slot-scope="scope">
						<div class="price">￥{{scope.row.order_price}}</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="created_at" label="实际到账金额">
					<template slot="header" slot-scope="">
						实际到账金额
						<el-tooltip class="item" effect="dark" content="自2021-08-08以后订单会显示" placement="top-start">
							<i class="el-icon-question"></i>
						</el-tooltip>
					</template>
					<template slot-scope="scope">
						<div class="price">￥{{scope.row.actual_price}}</div>
					</template>
				</el-table-column> -->
				<!-- <el-table-column prop="agent_account" label="充值账户"></el-table-column> -->
				<el-table-column prop="upload_img" label="上传的图片" width="120">
					<template slot-scope="scope">
						<div class="upload_img" @click="onimgDialog(scope.row)" v-if="scope.row.upload_img">
							<img :src="scope.row.upload_img" alt="">
						</div>
						<div v-else>垫款</div>
					</template>
				</el-table-column>
				<el-table-column prop="order_status" label="订单状态">
					<template slot-scope="scope">
						<el-tag :type="scope.row.order_status == 1 ? 'warning' : scope.row.order_status == 2 ? 'success' : scope.row.order_status == 3 ? 'warning' : ''">{{scope.row.order_status == 1 ? '处理中' : scope.row.order_status == 2 ? '充值完成' : scope.row.order_status == 3 ? '未处理' : ''}}</el-tag>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="created_at" label="充值类型">
					<template slot-scope="scope">
						{{scope.row.is_operating_type == 1  ? "代运营" : '自运营'}}
					</template>
				</el-table-column> -->
				<el-table-column prop="created_at" label="下单时间"></el-table-column>
				<el-table-column prop="arrival_time" label="预计到账时间">
					<template slot-scope="scope">
						<el-tag type="success" v-if="scope.row.order_status == 2">已到账</el-tag>
						<div v-else-if="scope.row.predict_finish_show_text != null">{{scope.row.predict_finish_show_text}}</div>
						<div v-else>--</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="lv-page"  :page-size="per_page" :pager-count="11"  @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page></el-pagination>
		</div>
		<el-dialog title="上传图片" :visible.sync="uploadimgDialog" min-width="500px">
			<span><img :src="uploadimgUrl" alt=""></span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	data () {
		return {
			OrderList:[],
			uploadimgDialog:false,
			uploadimgUrl:'',
			per_page:10,//一页多少条
			lvtotal:null,//共几条数据
			orderDetails:[],
			expands: [],//只展开一行放入当前行id
            getRowKeys(row){
                return row.order_id
            },
		}
	},

	mounted(){
		this.ongetOrderList(1)
	},

	methods: {
		// 自助充值列表
		ongetOrderList(val){
			var prame = {
				page:val
			}
			this.$service.get(this.$api.getOrderList,prame, (res)=> {
				if(res.code == '200'){
					this.OrderList = res.data.data
					this.lvtotal = res.data.total
				}
			})
		},
		//翻页
		DialogCurrentChange(val){
			this.ongetOrderList(val)
		},
		onimgDialog(row){
			// this.uploadimgUrl = row.upload_img
			// this.uploadimgDialog = true
			var imgurl = window.location.origin + '/advances/Advimage?imageurl=' + row.upload_img
			window.open(imgurl,'_blank')
		},
		//点击整行展开
		clickTable(row,index,e){
			this.$refs.refTable.toggleRowExpansion(row)
		},
		//某一行点击触发事件
		onChangeTableEx(row,e){
			if (e.length) {//说明展开了
				this.expands = []
				if (row) {
					this.expands.push(row.order_id)//只展开当前行id
					this.ongetOrderDetails(row)
				}
            } else {//说明收起了
              	this.expands = []
				this.orderDetails = []
            }
		},
		// 展开行调取详细数据
		ongetOrderDetails(row){
			var prame = {
				order_id:row.order_id
			}
			this.$service.get(this.$api.getOrderDetails,prame, (res)=> {
				if(res.code == '200'){
					this.orderDetails = res.data
				}
			})
		}
		
	}
}
</script>

<style lang="scss">
.advancesList{
	.advancesList-main{
		margin-top: 20px;
		.el-table{
			.cell{
				text-align: center;
			}
			.price{
				font-size: 18px;
				font-weight: 500;
				color: #f00;
			}
			.upload_img{
				width: 100px;
				height: 60px;
				cursor: pointer;
				img{
					width: 100%;
					height: 100%;
				}
			}
			.el-form{
				.el-form-item{
					display: block;
					margin-bottom: 0;
					.el-form-item__label{
						color: #99a9bf;
					}
					.el-form-item__content{
						color: #f00;
					}
				}
			}
		}
	}
}
</style>
