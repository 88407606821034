<template>
	<div class="gongdan">
		<!-- <div class="dan">
			<div class="text"><span>*</span>充值类型：</div>
			<div class="inputbox">
				<el-radio v-model="is_operating_type" label="1">代运营</el-radio>
				<el-radio v-model="is_operating_type" label="2">自运营</el-radio>
			</div>
		</div> -->
		<!-- <div class="dan">
			<div class="text"><span>*</span>群名：</div>
			<div class="inputbox">
				<el-select v-model="company_id" @change="onEnterprise" placeholder="请选择群名">
					<el-option
					v-for="item in CompanyList.list"
					:key="item.id"
					:label="item.company_name"
					:value="item.id">
					</el-option>
				</el-select>
			</div>
		</div> -->
		
		<div class="dan">
				<!-- <el-upload class="upload-demo" drag :action="origin + 'user.erlangcha.com/api/file/upload?token=' + token" :limit="2"  -->
			<div class="text"><span>*</span>上传转账截图：</div>
			<div class="inputbox">
				<el-upload class="upload-demo" drag :action="IndexUrl + '/api/file/upload?token=' + token" :limit="2" 
					ref="upload"
					list-type="picture"
					accept=".jpeg,.jpg,.png,.gif,"
					show-file-list
					:file-list="fileListUpload"
					:on-remove="onRemoveupload"
					:on-success="onSuccessupload"
					:on-change="onChangeUpload">
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					<div class="el-upload__tip" slot="tip">最多可传 1 个，扩展名为：.jpeg,.jpg,.png,.gif,</div>
				</el-upload>
			</div>
		</div>
		<div class="dan">
			<div class="text"><span>*</span>打款金额：</div>
			<div class="inputbox huakuai">
				<div class="amount">￥{{order_price}}</div>
				<div class="shuru"><el-input v-model="order_price" placeholder="请输入内容" type="text" oninput="value=value.replace(/[^\d\.]/g,'')"></el-input></div>
				<div class="tishi" v-if="imgaePiag"><i class="el-icon-loading"></i>识别中...</div>
				<div class="tishi" v-if="priceIdentify && order_price == 0" style="color:#f00">识别金额失败，请手动输入</div>
				<div class="tishi" style="margin-left:auto;">{{trichText}}</div>
			</div>
		</div>
		<div class="zengjia" v-for="(itemJia,key) in zengjiaList" :key="key">
			<div class="dan czzhjia">
				<div class="text">充值账户（{{key+1}}）<span v-if="zengjiaList.length > 1" @click="onJiaDel(key)">删除</span></div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>充值账户：</div>
				<div class="inputbox">
					<el-select v-model="itemJia.agent_account_name"  @visible-change="onAgentVisible" @change="onAgentChange" placeholder="请选择充值账户" >
						<el-option
						v-for="item in AgentAccount"
						:key="item.id"
						:label="item.agent_account_name + '（' + item.agent_account + '）'"
						:value="item.id">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>充值金额：</div>
				<div class="inputbox huakuai">
					<el-input v-model="itemJia.agentData" placeholder="请输入该账户充值金额" type="text" oninput="value=value.replace(/[^\d\.]/g,'')"></el-input>
					<!-- <el-input v-model="itemJia.agentData" placeholder="请输入该账户充值金额" type="text" oninput="value=value.replace(/[^\d\.]/g,'')"></el-input> -->
				</div>
			</div>
			
		</div>
		<div class="dan anniujia">
			<div class="text"><span></span></div>
			<div class="inputbox huakuai">
				<el-button icon="el-icon-plus" @click="onIncrease" round>增加充值账户</el-button>
			</div>
		</div>
		<div class="dan" v-if="zengjiaList[0].agentData">
			<div class="text"><span>*</span>广告币到账时间：</div>
			<div class="inputbox">
				<el-select v-model="arriveTime" value-key="id" placeholder="请选择到账时间" >
					<el-option
					v-for="(item,key) in timer_list"
					:key="key"
					:label="item.show_time"
					:value="item">
					</el-option>
				</el-select>
			</div>
		</div>
		<div class="enderQueren">
			<el-button type="primary" @click="onSubmit" :disabled="delenteAnniu">确认提交</el-button>
			<el-button type="info" @click="onChongxin" :disabled="delenteAnniu">重新填写</el-button>
		</div>
	</div>
</template>

<script>
import AdvancesList from './advancesList.vue'
export default {
	data () {
		return {
			token:JSON.parse(localStorage.getItem('TZ-USER')).token,
			is_operating_type:'1',
			CompanyList: [],//企业列表
			company_id: '',//企业ID
			AgentAccount: [],//企业下对应的账户列表
			agent_account_id: '',//充值账户id
			fileListUpload:[],//上传的文件列表
			file_id:'',//上传的文件id
			order_price:'0',//图片文件金额
			origin:window.location.origin,//url链接
			delenteAnniu:false,//禁用按钮
			agentList:[],//账户充值金额分
			zengjiaList:[
				{
					agent_account_name:'',
					agentData:'',
				}
			],
			timer_list:[],//到账时间
			arriveTime:'',
			bank_card_id:'',
			trichText:'',//随机一条提示
			imgaePiag:'',//识别金额加载中
			priceIdentify:false,//识别失败
		}
	},
	components:{
		AdvancesList,
	},

	mounted(){
		// this.ongetCompanyList()
		this.ongetAppointTimer()
		this.ongetDubVerbalTrick()
		this.ongetAgentAccount()
	},

	methods: {
		//随机一条提示
		ongetDubVerbalTrick(){
			var param = {
				type:2,
			}
			this.$service.get(this.$api.getDubVerbalTrick,param, (res)=> {
				if(res.code == '200'){
					this.trichText = res.data
				}
			})
		},
		//增加充值账户
		onIncrease(){
			this.zengjiaList.push({
				agent_account_name:'',
				agentData:'',
			})
		},
		//删除增加的元素对象
		onJiaDel(key){
			this.zengjiaList.splice(key,1)
		},
		// 充值金额，充值账户列
		onTopUpAmout(){
			this.agentList = []
			this.agent_account_id = []
			this.zengjiaList.forEach((v,k) => {
				this.agentList.push(v.agentData)
				this.agent_account_id.push(v.agent_account_name)
			});
		},
		//获取企业列表
		ongetCompanyList(){
			this.$service.get(this.$api.getCompanyList,{}, (res)=> {
				if(res.code == '200'){
					this.CompanyList = res.data
				}
			})
		},
		//企业id选择后出发事件
		onEnterprise(){
			this.agentList = []//清空每个充值金额
			this.agent_account_id = []//清空充值账户id
			this.agent_account_name = []//清空充值账户文字
			this.zengjiaList = [
				{
					agent_account_name:'',
					agentData:'',
				}
			],
			this.ongetAgentAccount()
		},
		//获取到账时间
		ongetAppointTimer(){
			this.$service.get(this.$api.getAppointTimer,{}, (res)=> {
				if(res.code == '200'){
					res.data.timer_list.forEach((v,k) => {
						v.id = k+1
					});
					this.timer_list = res.data.timer_list
					this.arriveTime = res.data.timer_list[0]
				}
			})
		},
		//获取充值账户列表
		ongetAgentAccount(){
			var prame = {
				// company_id:this.company_id
			}
			this.$service.get(this.$api.getAgentAccount,prame, (res)=> {
				if(res.code == '200'){
					this.AgentAccount = res.data
				}
			})
		},
		onAgentChange(e,r){
			// var accid = []
			// e.forEach((v,k) => {
			// 	accid.push(v.id)
			// });
			// this.agent_account_id = accid
			// this.agentList = []
		},
		//识别文件金额
		ongetImageOcr(){
			this.imgaePiag = true
			var prame = {
				file_id:this.file_id
			}
			this.$service.post(this.$api.getImageOcr,prame, (res)=> {
				this.imgaePiag = false
				if(res.code != '200'){
					this.priceIdentify = true
				}
				if(res.code == '200'){
					this.bank_card_id = res.data.bank_card.bank_card_id
					this.order_price = res.data.price
				}
			})
		},
		//充值账户id选择前触发事件
		onAgentVisible($event){
			// console.log($event)
			// if($event && this.company_id == ''){
			// 	this.$message({message: '请先选择企业',type: 'warning'});
			// }
		},
		//上传文件移出
		onRemoveupload(file, fileList){
			console.log('上传文件移出',fileList)
			this.fileListUpload = fileList
			this.file_id = ''
			this.$message({message: '文件移出成功，请重新上传文件',type: 'success'});
		},
		//文件上传成功
		onSuccessupload(res, file,fileList){
			if (fileList.length > 1) {
				fileList.splice(0, 1);
			}	
			if(res.code == 200){
				this.file_id = res.data.id
				this.ongetImageOcr()
			}
		},
		//文件上传成功，失败 所有都会调用
		onChangeUpload(file,fileList){
			console.log(file,'文件上传成功，失败 所有都会调用')
		},
		// 判断agentList不能为空
		onAgeList(){
			if(this.agentList.length != this.agent_account_id.length){return true}
			else {
				var fatr = ''
				this.agentList.find(value => {
					if (value == '' || value == undefined){
						fatr=true
					}
				})
				return fatr
			}
		},
		//确认提交
		onSubmit(){
			this.onTopUpAmout()
			if(this.is_operating_type == ''){this.$message({message: '请选择充值类型',type: 'warning'}); return}
			// if(this.company_id == ''){this.$message({message: '请先选择企业id',type: 'warning'}); return}
			if(this.agent_account_id == ''){this.$message({message: '请先选择充值账户',type: 'warning'}); return}
			if(this.file_id == ''){this.$message({message: '请先上传图片',type: 'warning'}); return}
			if(this.order_price == ''){this.$message({message: '正在识别图片金额',type: 'warning'}); return}
			if(this.onAgeList()){this.$message({message: '请填写每个账户充值的金额',type: 'warning'}); return}
			var prame = {
				is_operating_type:this.is_operating_type,//充值类型：1代运营 2 自运营
				// company_id:this.company_id,//企业ID
				agent_account_id:this.agent_account_id,//充值账户id
				file_id:this.file_id,//文件id
				order_price:this.order_price,//充值金额
				is_payment_wat:1,//支付方式：1预付款 2垫款
				user_record_price:this.agentList,//每个金额
				start_time:this.arriveTime.start_time,//开始时间
				end_time:this.arriveTime.end_time,//结束时间
				bank_card_id:this.bank_card_id,
			}
			this.delenteAnniu = true
			this.$service.post(this.$api.placeTheOrder,prame, (res)=> {
				if(res.code == '200'){
					if(res.data == null){
						this.$message({message: '提交成功',type: 'success'});
						setTimeout(() => {
							this.onChongxin()
							this.advancesList()
						}, 1000);
					}else if(res.data.status == 0){
						this.ongetAppointTimer()
						this.$message({message: res.msg,type: 'warning'});
					}
					
				}
			})
			setTimeout(() => {
				this.delenteAnniu = false
			}, 1000);
		},
		//重新填写
		onChongxin(){
			this.company_id = '',
			this.agent_account_id = ''
			this.file_id = ''
			this.order_price = '0'
			this.fileListUpload = []
			this.zengjiaList=[
				{
					agent_account_name:'',
					agentData:'',
				}
			]
		},
		//跳转列表
		advancesList(){
			this.$router.go(0);
			// this.$router.push('/advances/advancesList')
		},
	}
}
</script>

<style lang="scss" scoped>
.advances{
	.advances-main{
		margin-top: 20px;
		.gongdan{
			display: block;
			.enderQueren{
				text-align: center;
				width: 800px;
			}
			.dan{
				&:last-child{
					margin-top: 0;
				}
				.huakuai{
					display: flex;
					align-items: center;
					.tishi{
						margin-left: 20px;
						color: #999;
					}
				}
				.shuru{
					width: 100px;
					margin-left: 20px;
				}
				.amount{
					min-width: 120px;
					color: #f00;
					font-size: 24px;
					font-weight: 500;
				}
				&.anniujia{
					margin-top: 20px;
				}
				&.czzhjia{
					padding-bottom: 10px;
					span{
						cursor: pointer;
					}
				}
			}
			.zengjia{
				padding-top: 20px;
				// border-bottom: 1px solid #ededed;
				.dan{
					margin-bottom: 0;
					&:nth-child(3){
						.inputbox{
							padding: 20px 0;
							border-bottom: 1px solid #ededed;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 750px) {
	.advances .advances-main .gongdan .enderQueren{
		width: auto;
	}
	.gongdan .dan:last-child .text{
		margin-top: 0;
	}
}
</style>
